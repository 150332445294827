.clear-filter[filter-color="black"] {
  @include linear-gradient(rgba($black-color,.20), rgba(0, 0, 0, 0));
}
[data-background-color="black"]{
    background-color: $black-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $black-color;
}
.page-header{
  &.page-header-small{
    min-height: 60vh;
    max-height: 640px;
  }
}
